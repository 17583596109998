<template>
  <n-config-provider :theme="REF_Theme.theme">
    <n-message-provider>
      <router-view/>
    </n-message-provider>
  </n-config-provider>
</template>
<script setup>
import {onMounted, onUnmounted, provide, ref} from "vue";
// eslint-disable-next-line no-unused-vars
import {darkTheme, lightTheme, useMessage} from 'naive-ui'

// 设置默认主题 亮色
const REF_Theme = ref({mode: 'light', theme: lightTheme})
provide('REF_Theme', REF_Theme);

// 手机端适配
const REF_IsMobile = ref(false)
provide('REF_IsMobile', REF_IsMobile);
const FUN_CheckIsMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  REF_IsMobile.value = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}


// 监听屏幕大小变化
onMounted(() => {
  FUN_CheckIsMobile();
  window.addEventListener('resize', FUN_CheckIsMobile);
})
onUnmounted(() => {
  window.removeEventListener('resize', FUN_CheckIsMobile);
})
</script>
<style>
</style>
