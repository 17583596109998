import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import DatabaseView from "@/views/database/databaseView.vue";
import ZhView from "@/views/database/ZhView.vue";
import EhView from "@/views/database/EhView.vue";
import MiddleView from "@/views/middleView.vue";
import thatStorage from "@/utils/thatStorage";

const routes = [
  {
    path: '/',
    name: 'middle',
    component: MiddleView,
    redirect: '/menu',
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
        redirect: '/database/zh',
        meta: {
          title: '主页'
        },
        children: [
          {
            path: 'database',
            name: 'database',
            meta: {
              title: '数据库'
            },
            component: DatabaseView,
            children: [
              {
                path: 'zh',
                name: 'database-zh',
                meta: {
                  title: '中文数据库'
                },
                component: ZhView,
              },

              {
                path: 'en',
                name: 'database-en',
                meta: {
                  title: '英文数据库'
                },
                component: EhView,
              }
            ]
          },
          {
            path: 'about',
            name: 'about',
            meta: {
              title: '关于'
            },
            component: () => {
              return import('@/views/AboutView.vue')
            }
          },
          {
            path: 'admin',
            name: 'admin',
            meta: {
              title: '管理'
            },
            auth: true,
            component: () => {
              return import('@/views/admin/adminView.vue')
            },
            redirect: '/admin/menu',
            children: [
              {
                path:'menu',
                name: 'admin-menu',
                meta: {
                  title: '菜单管理'
                },
                component: () => {
                  return import('@/views/admin/MenuManageView.vue')
                }
              }
            ]
          }
        ]
      },
      {
        path: '/login',
        name: 'login',
        component: () => {
          return import('@/views/LoginView.vue')
        }
      },
      {
        path: '/create',
        name: 'create',
        component: () => {
          return import('@/views/admin/createView.vue')
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = '文献导航站'

  if (to.path === '/create'){
    next()
  }

  // 登录验证
  if (to.path !== '/login') {
    if (!thatStorage.getItem('token')) {
      next('/login')
    }
  }

  // 权限验证
  if ((to.path.startsWith('/admin') || to?.auth) && !thatStorage.getItem('admin')){
    next('/')
  }
  // window.$message.info('路由跳转：' + to?.meta?.title)
  // 放行
  next()
})

export default router
