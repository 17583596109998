import proAxios from '@/api/MyAxios';


const proxyUrl = '/proxy/service/admin';

const userUrlBase = {
    menu: '/menu',
    library: '/menu/library',
};

const getMenu = async () => {
    // window.$message.info('请求：获取菜单')
    const res = await proAxios({
        url: proxyUrl + userUrlBase.menu,
        method: 'GET',
    })
    if (res.Then) {
        return res.Sources
    } else {
        return false
    }
}

const saveMenu = async (data) => {
    const res = await proAxios({
        url: proxyUrl + userUrlBase.menu,
        method: 'PUT',
        data: data,
    })
    if (res.Then) {
        return res.Sources
    }
    return false
}

const deleteMenu = async (menuId) => {
    const res = await proAxios({
        url: proxyUrl + userUrlBase.menu + `/${menuId}`,
        method: 'DELETE',
    })
    if (res.Then) {
        return res.Sources
    }
    return false
}

export const getLibrary = async (model="", urlId=1) => {
    const res = await proAxios({
        url: proxyUrl + userUrlBase.library,
        method: 'GET',
        params: {
            model: model,
            urlId: urlId,
        }
    })
    if (res.Then) {
        return res.Sources
    }
    return false
}

export default {getMenu, saveMenu, deleteMenu, getLibrary}
