<template>
  <div class="home-view">
    <n-watermark
        v-if="true"
        :content="thatStorage.getItem('userName') || '文献'"
        cross
        fullscreen
        :font-size="16"
        :font-color="'rgba(128, 128, 128, .15)'"
        :line-height="16"
        :width="384"
        :height="384"
        :x-offset="12"
        :y-offset="60"
        :rotate="-15"
    />
    <n-layout position="absolute" :style="{ height: '100%', width: '100%' }">
      <n-page-header class="home-header" bordered>
        <template #title>
          <!--          <a-->
          <!--              href="/"-->
          <!--              style="text-decoration: none; color: inherit"-->
          <!--          >文献</a>-->
          <n-tag round size="large" :bordered="false">
            文献导航站
            <template #avatar>
              <n-avatar
                  :src="IMG_ZHIWANG"
              />
            </template>
          </n-tag>
        </template>
        <template #subtitle>
          <n-menu
              v-if="!REF_IsMobile && REF_MainMenu.length > 0"
              v-model:value="REF_MainMenuOpenKeys"
              mode="horizontal"
              :options="REF_MainMenu"
              responsive
              @update:value="Fun_ChangeMainMenuOpenKeys"
          />
        </template>
        <template #extra>
          <n-button-group>
            <n-button :size="!REF_IsMobile? 'medium' :'tiny'" strong secondary round
                      :type="REF_Theme.mode === 'light' ? 'tertiary' : 'warning'" @click="Fun_ChangeTheme">
              <template #icon>
                <n-icon v-if="REF_Theme.mode === 'light' ">
                  <moon-icon/>
                </n-icon>
                <n-icon v-else>
                  <sunny-icon/>
                </n-icon>
              </template>
              {{ REF_Theme.mode === 'light' ? '关灯' : '开灯' }}
            </n-button>
            <n-button :disabled="true" :size="!REF_IsMobile? 'medium' :'tiny'" strong secondary round type="error">
              {{FUN_OVERDUE()}}
            </n-button>
            <n-button v-if="REF_IsMobile" @click="Fun_ToggleMobileMenu" :size="!REF_IsMobile? 'medium' :'tiny'" strong
                      secondary round>
              {{ REF_MobileMenuOpen ? '收起菜单' : '展开菜单' }}
            </n-button>
            <n-button :size="!REF_IsMobile? 'medium' :'tiny'" strong secondary round type="error"
                      @click="FUN_Logout">
              登出
            </n-button>
          </n-button-group>
        </template>
        <template #footer>
          <div v-if="REF_IsMobile" style="text-align: center">
            <span style="color: red;font-size: large;font-weight: bold">本站所有内容均来源于互联网，仅供学习交流</span><br>
            <span style="color: blue;font-size: large;font-weight: bold">详情请咨询客服</span> + <span
              style="color: red;font-size: large;font-weight: bold">VX: fhjy556677</span>
          </div>
          <div v-else style="text-align: center">
            <n-carousel autoplay :interval="3000">
              <img
                  v-for="(item, index) in BANNERS"
                  class="carousel-img"
                  :src="item"
                  :key="index"
                  :alt="'banner'+ index" />
            </n-carousel>
          </div>
        </template>
      </n-page-header>
      <n-menu
          v-if="REF_IsMobile && REF_MobileMenuOpen && REF_MainMenu.length > 0"
          v-model:value="REF_MainMenuOpenKeys"
          :root-indent="36"
          :indent="12"
          :options="REF_MainMenu"
          @update:value="Fun_ChangeMainMenuOpenKeys"
      />
      <n-layout has-sider class="home-main">
        <n-layout-sider
            bordered
            show-trigger
            collapse-mode="width"
            :collapsed-width="64"
            :width="240"
            :native-scrollbar="false"
            v-if="!REF_IsMobile"
        >
          <n-menu
              v-if="!REF_IsMobile && REF_MainMenu.length > 0"
              v-model:value="REF_MainMenuOpenKeys"
              :collapsed-width="64"
              :collapsed-icon-size="22"
              :options="REF_MainMenu"
              @update:value="Fun_ChangeMainMenuOpenKeys"
          />
        </n-layout-sider>
        <n-layout v-if="!OVERDUE" class="home-main-content">
          <router-view/>
        </n-layout>
        <n-layout v-else class="home-main-content" style="display: flex;flex-direction: column;align-items: center;justify-content: center;height: 100%;">
          <h1>
            --尊贵的用户，您的账号已过期，请联系客服购买--
          </h1>
          <h1><a  target="_blank" href="https://shop255953689.taobao.com/?spm=pc_detail.29232929/evo365560b447259.shop_block.dshopinfo.4ae77dd6rwirde"> 点击前往淘宝客服购买 </a></h1>
        </n-layout>
      </n-layout>
      <n-layout-footer class="home-footer">
        <n-affix
            v-if="!REF_IsMobile"
            :bottom="120"
            :trigger-bottom="60"
            style="z-index: 10000;right: 3rem;display: flex;flex-direction: column;align-items: center;justify-content: center"
        >
          <n-qr-code :value="'https://u.wechat.com/MPruXVVQWhRZIAW81hzU4bY'" type="canvas"/>
          <p>扫码添加客服</p>
        </n-affix>
        <div style="text-align: center">
          © 2024 文献导航站
        </div>
      </n-layout-footer>
    </n-layout>
  </div>
</template>
<script setup>
import {darkTheme, lightTheme, NIcon} from 'naive-ui'
import {
  BookOutline as BookIcon,
  Moon as MoonIcon,
  Sunny as SunnyIcon
} from '@vicons/ionicons5'
import {inject, ref, h, provide} from "vue";
import thatStorage from "@/utils/thatStorage";
import router from "@/router";
import IMG_ZHIWANG from "@/assets/image/zhiwang.jpg";
import {menuController} from "@/api/controller";
import BANNER1 from '@/assets/image/banner-01.png'
import BANNER2 from '@/assets/image/banner-02.jpg'
import BANNER3 from '@/assets/image/banner-03.jpg'
const BANNERS = [BANNER1, BANNER2, BANNER3]

const OVERDUE = ref(false)
// 手机模式下菜单收起
const REF_IsMobile = inject('REF_IsMobile');
const REF_MobileMenuOpen = ref(false)
const Fun_ToggleMobileMenu = () => {
  REF_MobileMenuOpen.value = !REF_MobileMenuOpen.value
}

// 主题模块
const REF_Theme = inject('REF_Theme');
// 切换主题
const Fun_ChangeTheme = () => {
  REF_Theme.value.mode = REF_Theme.value.mode === 'light' ? 'dark' : 'light'
  thatStorage.setItem("theme", REF_Theme.value.mode)
  REF_Theme.value.theme = REF_Theme.value.mode === 'light' ? lightTheme : darkTheme
}
// 读取本地存储的主题
if (thatStorage.getItem("theme") === 'dark') {
  REF_Theme.value.mode = 'dark'
  REF_Theme.value.theme = darkTheme
}


// 主菜单相关
// 渲染菜单图标
const FUN_RenderIcon = (icon) => {
  return () => h(NIcon, null, {default: () => h(icon)})
}
// 菜单开关
// const REF_MainMenuOpen = ref(false)
// 菜单选项
const REF_MainMenuOpenKeys = ref(null)
provide('REF_MainMenuOpenKeys', REF_MainMenuOpenKeys);
// 菜单数据
const REF_MainMenu = ref([])
const REF_MenuList = ref([])
// 切换菜单
const Fun_ChangeMainMenuOpenKeys = (key, item) => {
  if (item.url.startsWith('http')){
    window.open(item.url)
    return
  }
  router.push(item.url)
}

// 获取菜单
const FUN_GetMenu = async () => {
  const res = await menuController.getMenu()
  const menu = res?.data?.row
  if (menu && menu.length > 0) {
    REF_MenuList.value = menu
    REF_MainMenu.value = []
    for (const item of menu) {
      REF_MainMenu.value.push(
          {
            label: item.title,
            key: item.url.replace('/', '').replaceAll('/', '-'),
            url: item.url,
            icon: FUN_RenderIcon(BookIcon),
            isAdmin: item.isAdmin
          }
      )
    }
    // REF_MenuList.value.push({
    //   label: '淘宝查重',
    //   key: 'taobao-check',
    //   url: '/taobao-check',
    //   icon: FUN_RenderIcon(BookIcon),
    //   isAdmin: false
    // })
  }else {
    OVERDUE.value = true
    FUN_Logout()
  }
}

const FUN_Logout = () => {
  thatStorage.clear()
  router.push("/login")
}

const FUN_OVERDUE = () => {
  if (OVERDUE.value) {
    return ''
  }
  // 13位时间戳
  const overdue = thatStorage.getItem('overdue')
  // 计算距离现在还有多少天
  if (overdue) {
    if(overdue === -1){
      return `永久会员`
    }
    const now = new Date().getTime()
    const days = Math.round((Number(overdue) - now) / (24 * 3600 * 1000))
    return `剩余${days}天`
  }
}

// 页面初始化
const PAGE_INIT = async () => {
  await FUN_GetMenu()
}
PAGE_INIT()
provide('REF_MenuList', REF_MenuList);
provide('FUN_GetMenu', FUN_GetMenu);
</script>
<script>
export default {
  name: 'HomeView'
}
</script>

<style>
.home-view {
  height: 100%;
}

.home-header {
  height: auto;
  padding: 0.6rem;
}

.home-main {
  height: auto;
  margin-bottom: 2.4rem;
}

.home-main-content {
  padding: 0.6rem;
}

.home-footer {
  padding: 0.6rem;
  background: var(--n-color);
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  height: 2.4rem;
}

@media screen and ( max-width: 1200px ) {
  .home-view {
    height: 100%;
  }

  .home-header {
    height: auto;
    padding: 0.6rem;
  }

  .home-main {
    height: auto;
  }

  .home-main-content {
    padding: 0.6rem;
  }
}

.carousel-img {
  width: 100%;
  object-fit: cover;
}

iframe html {
  scrollbar-width: none
}
</style>
