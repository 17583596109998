<template>
  <n-alert :type="propsType" :show-icon="false">
    <span class="tip-head">提示！</span>任何问题右侧客服联系处理（推荐使用<n-a href="https://www.google.cn/chrome/" target="_blank">《google浏览器》</n-a>、<n-a href="https://www.firefox.com.cn/" target="_blank">《火狐浏览器》</n-a>、<n-a href="https://browser.360.cn/" target="_blank">《360浏览器》</n-a>)
    <br>
    <span class="tip-head">必看！</span>登录后请核实账号有效期与订购是否一致,不一致说明遇到了二道贩子倒卖,请立即退款,本站不予售后!
  </n-alert>
</template>
<script setup>
import { defineProps } from 'vue'
defineProps({
  propsType: {
    type: String,
    default: 'warning'
  }
})
</script>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TopTips'
})
</script>
<style scoped>
.tip-head {
  color: red;
  font-size: larger;
}
</style>