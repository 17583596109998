<template>
  <router-view />
</template>
<script setup>

</script>
<script>
export default {
  name: 'databaseView',
}
</script>
<style scoped>

</style>