<script setup>
import { useMessage } from "naive-ui";
// 暴露通知组件
const message = useMessage();
const msgType = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
  loading: "loading"
}
const editMessage = (key, msg, type = "success") => {
  if (window.$message.msgReactive[key]) {
    window.$message.msgReactive[key].content = msg;
    window.$message.msgReactive[key].type = type;
  }
  setTimeout(() => {
    window.$message.msgReactive[key].destroy();
    delete window.$message.msgReactive[key];
  }, 2000)
}
const newMessage = (msg, type = "loading") => {
  const key = Date.now() + Math.random() * 1000
  window.$message.msgReactive[key] = message.create(msg, {
    type: type,
    duration: 0
  });
  return key;
}
const normalMessage = (msg, type) => {
  message.create(msg, {
    type: type,
    duration: 2000
  })
}
window.$message = {
  msgReactive: {},
  close: editMessage,
  new: newMessage,
  type: msgType,
  info: (msg) => normalMessage(msg, "info"),
  success: (msg) => normalMessage(msg, "success"),
  error: (msg) => normalMessage(msg, "error"),
  warning: (msg) => normalMessage(msg, "warning"),
  loading: (msg) => normalMessage(msg, "loading")
}
</script>

<template>
<router-view/>
</template>

<style scoped>

</style>