<template>
  <TopTips :props-type="'warning'"/>
  <n-collapse v-if="REF_ZH_NAV_LIST.length > 0" style="margin-top: .8rem;" :default-expanded-names="REF_ZH_NAV_LIST.map((item, index) => String(index + 1))">
    <n-collapse-item v-for="(item, index) in REF_ZH_NAV_LIST" v-bind:key="index" :title="item.title"
                     :name="''+(index+1)">
      <template #header-extra>
        {{ item?.more }}
      </template>
      <n-grid :x-gap="12" :y-gap="8" cols="12" item-responsive>
        <n-grid-item v-for="(child, index) in item.children" v-bind:key="index"
                     span="12 280:6 400:6 600:4 800:3 1200:2 1400:2 1600:2 1800:1">
          <n-card class="green" embedded>
            <n-text :underline="false" strong><n-a :href="child.url"  target="_blank">{{ child.title }}</n-a></n-text>
          </n-card>
        </n-grid-item>
      </n-grid>
    </n-collapse-item>
  </n-collapse>
</template>
<script setup>
import TopTips from "@/components/TopTips.vue";
import {inject} from "vue";

const REF_MainMenuOpenKeys = inject('REF_MainMenuOpenKeys');
const REF_MenuList = inject('REF_MenuList');
REF_MainMenuOpenKeys.value = 'database-en'
const PAGE_URL = '/database/en'

let REF_ZH_NAV_LIST = ref([]);

let intervalId;

// 定义PAGE_INIT函数
const PAGE_INIT = () => {
  // 检查REF_MENU_LIST.value.length是否大于0
  REF_ZH_NAV_LIST.value = REF_MenuList.value?.filter(item => item.url === PAGE_URL)[0]?.menuData?.menuData || []
  if (REF_MenuList.value.length > 0) {
    // 如果大于0，清除定时器
    clearInterval(intervalId);
  }
};

// 启动定时器，每0.5秒执行一次PAGE_INIT
intervalId = setInterval(PAGE_INIT, 100);
</script>
<script>
import {ref} from "vue";

export default {
  name: 'EhView',
}
</script>
<style scoped>
.light-green {
  height: 108px;
  background-color: rgba(0, 128, 0, 0.12);
}

.green {
  border-radius: .5rem;
  height: 2.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.green:hover {
  background-color: rgba(0, 128, 0, 0.24);
}
</style>