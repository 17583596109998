import proAxios from '@/api/MyAxios';


const proxyUrl = '/proxy/service/welcome';

const userUrlBase = {
    login: '/login',
    register: '/register',
};

const login = async (data) => {
    const loginRes = (await proAxios({
        url: proxyUrl + userUrlBase.login,
        method: 'post',
        data: JSON.parse(JSON.stringify(data)),
    }));
    if (loginRes.Then) {
        return loginRes.Sources
    } else {
        return false
    }
}

const register = async (data) => {
    const registerRes = (await proAxios({
        url: proxyUrl + userUrlBase.register,
        method: 'post',
        token: false,
        data
    }))
    if (registerRes.Then) {
        return registerRes.Sources
    } else {
        return false
    }
}

const create = async (day=0) => {
    const res = await proAxios({
        url: proxyUrl + '/create',
        method: 'GET',
        params: {
            day
        }
    })
    if (res.Then) {
        return res.Sources
    }
}

export default {login, register, create}
